<!-- <app-e-navbar *appHasRole="'site_admin,rep_admin,rep_user'"></app-e-navbar>
<app-navbar *appHasRole="'customer_admin,customer_user'" [IsAvailInvoiceCustomer]=IsAvailInvoiceCustomer></app-navbar> -->

<app-e-navbar *ngIf="securityService.userRole == 'site_admin' || securityService.userRole == 'rep_admin' || securityService.userRole == 'rep_user'"></app-e-navbar>
<app-navbar  *ngIf="securityService.userRole == 'customer_admin' || securityService.userRole == 'customer_user' " [IsAvailInvoiceCustomer]=IsAvailInvoiceCustomer></app-navbar>
<!-- <app-notification></app-notification> -->
<div class="wrapper page-{{localeCode}}-bg">
    <div class="page-header page-header-mini">
        <div class="page-header-image rellax-header" data-rellax-speed="-8"
        ><!--style='background-image:linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url("assets/img/pp-cov.jpg");background-size:cover;'-->
        </div>
    </div>
    <!-- <app-global-note></app-global-note> -->
    <app-loader></app-loader>
    <div style="min-height: 79.1vh;padding-top: 2rem; padding-bottom:1rem;" [ngStyle]="{'padding-top': isHomePage ? '0' : '2rem' }">
        <div *ngIf="showSlider && router.url=='/home' && configurations.defaultLayout.showHomeBanner">
          <div fxLayout="column" *ngIf="showDiscountBanner" class="px-2">
            <div fxLayout="row wrap" fxLayoutAlign="space-between start">
              <div fxFlex="33" *ngFor="let aBanner of configurations.defaultLayout.discountBanner" (click)="onDiscountBannerClick(aBanner)" class="cusrsor-pointer">
                <img [src]="imageConfig.highResUri+aBanner.banner+'.jpg'" alt="" width="100%" height="300"></div>
            </div>
          </div>
          <app-slide-show [options]="subSlideOptions" [items]="images|async" #slideShow></app-slide-show>
            <!-- <app-slide-show [options]="subSlideOptions2" [items]="lowResImages|async" #slideShow></app-slide-show> -->
        </div>
        <div *ngIf="router.url=='/home'">
          <!-- <owl-carousel #owlElement
                [options]="subSlideOptions"
                [carouselClasses]="['owl-theme', '', 'sliding']">
                <div class="item" *ngFor="let image of configurations.defaultLayout.homeBanners;let i = index">
                    <img style="width: 100%;" [src]="imageConfig.highResUri+image+'.jpg'" appImagePreloadDir>
                </div>
          </owl-carousel> -->
          <owl-carousel-o [options]="customOptions" *ngIf="configurations.defaultLayout.showHomeBanner">
            <ng-container class="item" *ngFor="let image of configurations.defaultLayout.homeBanners;let i = index">
              <ng-template carouselSlide [width]="100">
                <img style="width: 100%;" [src]="imageConfig.highResUri+image+'.jpg'" appImagePreloadDir>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
       </div>
       <!-- <div class="top-slider" *ngIf="router.url=='/home' && configurations.defaultLayout.showOwlCarousel">
          <owl-carousel #owlElement
                [options]="subSlideOptions"
                [items]="sliderImages"
                [carouselClasses]="['owl-theme', '', 'sliding']">
                <div class="item" *ngFor="let image of sliderImages;let i = index">
                    <img style="width: 100%;" [src]="imageConfig.highResUri+image.src" appImagePreloadDir>
                </div>
          </owl-carousel>
       </div> -->
       <!-- <div class="top-slider" *ngIf="router.url=='/home' && configurations.defaultLayout.showOwlCarouselEU">
        <owl-carousel #owlElement
              [options]="subSlideOptions"
              [items]="sliderImageseu"
              [carouselClasses]="['owl-theme', '', 'sliding']">
              <div class="item" *ngFor="let image of sliderImageseu;let i = index">
                  <img style="width: 100%;" [src]="imageConfig.highResUri+image.src" appImagePreloadDir>
              </div>
        </owl-carousel>
      </div> -->
        <section style="padding:30px;margin:10px auto;display:none" *ngIf="configurations.defaultLayout.showHomeCategoryWithImg && isHomePage">
            <ul class="l-diamonds">
              <li>
                <a [routerLink]="['/catalog/PLU']" class="diamond">
                  <div class="diamond__content">
                    <h2 class="diamond__title">PLUSH</h2>
                    <!-- <span class="diamond__subtitle">CDO</span> -->
                    <img src="assets/img/plush.jpg"/>
                  </div>
                    <h3 class=" text-center visible-xs hidden-md hidden-lg hidden-md">PLUSH</h3>
                </a>
              </li>
              <li>
                <a [routerLink]="['/catalog/TOY']" class="diamond">
                  <div class="diamond__content">
                    <h2 class="diamond__title">TOYS</h2>
                    <!-- <span class="diamond__subtitle">CDO</span> -->
                    <img src="assets/img/toys.jpg"/>
                  </div>
                  <h3 class=" text-center visible-xs hidden-md hidden-lg hidden-md">TOYS</h3>
                </a>
              </li>
              <li>
                <a [routerLink]="['/catalog/ECOKINS']" class="diamond">
                  <div class="diamond__content">
                    <h2 class="diamond__title">ECOKINS</h2>
                    <!-- <span class="diamond__subtitle">CDO</span> -->
                    <img src="assets/img/ecokins.jpg"/>
                  </div>
                  <h3 class=" text-center visible-xs hidden-md hidden-lg hidden-md">ECOKINS</h3>
                </a>
              </li>
              <li>
                <a [routerLink]="['/catalog/ECOKINS']" class="diamond">
                  <div class="diamond__content">
                    <h2 class="diamond__title">RUBBER DUCKS</h2>
                    <!-- <span class="diamond__subtitle">CDO</span> -->
                    <img src="assets/img/rubber-ducks.jpg"/>
                  </div>
                  <h3 class=" text-center visible-xs hidden-md hidden-lg hidden-md">RUBBER DUCKS</h3>
                </a>
              </li>
              <li>
                <a [routerLink]="['/catalog/ECOKINS']" class="diamond">
                  <div class="diamond__content">
                    <h2 class="diamond__title">WILD SMILES</h2>
                    <!-- <span class="diamond__subtitle">CDO</span> -->
                    <img src="assets/img/mask.jpg"/>
                  </div>
                  <h3 class=" text-center visible-xs hidden-md hidden-lg hidden-md">WILD SMILES</h3>
                </a>
              </li>

              <li>
                <a [routerLink]="['/catalog/ECOKINS']" class="diamond">
                  <div class="diamond__content">
                    <h2 class="diamond__title">BIRDS WITH SOUNDS</h2>
                    <!-- <span class="diamond__subtitle">CDO</span> -->
                    <img src="assets/img/audubon.jpg"/>
                  </div>
                  <h3 class=" text-center visible-xs hidden-md hidden-lg hidden-md">BIRDS WITH SOUNDS</h3>
                </a>
              </li>
              <li>
                <a [routerLink]="['/catalog/ECOKINS']" class="diamond">
                  <div class="diamond__content">
                    <h2 class="diamond__title">HUGGERS</h2>
                    <!-- <span class="diamond__subtitle">CDO</span> -->
                    <img src="assets/img/huggers.jpg"/>
                  </div>
                  <h3 class=" text-center visible-xs hidden-md hidden-lg hidden-md">HUGGERS</h3>
                </a>
              </li>
            </ul>
          </section>

        <div class="" *ngIf="configurations.defaultLayout.showDiscounts && isHomePage" >
            <div class="alert alert-info no-margin text-center">
                <span>{{('home.DISOCUNTNOTES'|translate)}}</span>
            </div>
            <div id="image_map_mobile" class="visible-xs hidden-md hidden-lg" style="display: flex !important;flex-wrap: wrap;position: relative;">
                <img src="{{imageConfig.highResUri}}TradeFair.jpg" [routerLink]="['/catalog']" style="width: 100%;position: absolute" ><!--position: absolute;-->
                <div class="col-md-4" style="padding: 0;max-width: 33.33%;" [routerLink]="['/catalog','PILLOWKINS']">
                    <img src="{{imageConfig.lowResUri}}TradeFair_01.jpg" [routerLink]="['/catalog','PILLOWKINS']" style="width: 100%;" >
                    <span class="ofr-ctn" style="display: none;">Pillowkins</span>
                </div>
                <div class="col-md-4" style="padding: 0;max-width: 33.33%;" [routerLink]="['/catalog/PLU']">
                    <img src="{{imageConfig.lowResUri}}TradeFair_02.jpg" [routerLink]="['/catalog/PLU']" style="width: 100%;">
                    <span class="ofr-ctn" style="display: none;">Plush</span>
                </div>
                <div class="col-md-4" style="padding: 0;max-width: 33.33%;" [routerLink]="['/catalog/TOY']">
                    <img src="{{imageConfig.lowResUri}}TradeFair_03.jpg" [routerLink]="['/catalog/TOY']">
                    <span class="ofr-ctn" style="display: none;">Toys</span>
                </div>
                <div class="col-md-4" style="padding: 0;max-width: 33.33%;" [routerLink]="['/catalog/ECOKINS']">
                    <img src="{{imageConfig.lowResUri}}TradeFair_03.jpg" [routerLink]="['/catalog/ECOKINS']">
                    <span class="ofr-ctn" style="display: none;">Ecokins</span>
                </div>
                <div class="col-md-4" style="padding: 0;max-width: 33.33%;" [routerLink]="['/catalog/NEW']">
                    <img src="{{imageConfig.lowResUri}}TradeFair_03.jpg" [routerLink]="['/catalog/NEW']">
                    <span class="ofr-ctn" style="display: none;">New Releases</span>
                </div>
                <div class="col-md-4" style="padding: 0;max-width: 33.33%;" [routerLink]="['/catalog/POCKETKINS']">
                    <img src="{{imageConfig.lowResUri}}TradeFair_03.jpg" [routerLink]="['/catalog/POCKETKINS']">
                    <span class="ofr-ctn" style="display: none;">Pocketkins</span>
                </div>
                <div class="col-md-4" style="padding: 0;max-width: 33.33%;" [routerLink]="['/catalog/PLSH-SNAKE']">
                    <img src="{{imageConfig.lowResUri}}TradeFair_03.jpg" [routerLink]="['/catalog/PLSH-SNAKE']">
                    <span class="ofr-ctn" style="display: none;">Snakes</span>
                </div>
                <div class="col-md-4" style="padding: 0;max-width: 33.33%;" [routerLink]="['/catalog/JUMBO']">
                    <img src="{{imageConfig.lowResUri}}TradeFair_03.jpg" [routerLink]="['/catalog/JUMBO']">
                    <span class="ofr-ctn" style="display: none;">Jumbos</span>
                </div>
                <div class="col-md-4" style="padding: 0;max-width: 33.33%;" [routerLink]="['/catalog/MSK']">
                    <img src="{{imageConfig.lowResUri}}TradeFair_03.jpg" [routerLink]="['/catalog/MSK']">
                    <span class="ofr-ctn" style="display: none;">Face Masks</span>
                </div>

            </div>
        </div>
        <div class="container">
            <!-- <div *ngIf="securityService.userRole!=='customer_admin' && securityService.userRole!=='customer_user'">
                <app-customer-info *ngIf="basketService.getCustomer() as customerInfo" [customerInfo]="customerInfo" ></app-customer-info>
            </div> -->

            <router-outlet></router-outlet>
        </div>
    </div>

    <footer [ngClass]="'footer'" [attr.data-background-color]="configurations.defaultLayout.showFooterColorBlack ?'black':''"><!--data-background-color="black"-->
            <app-footer></app-footer>
            </footer>
</div>
